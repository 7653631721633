import { TextPosition } from "./AchievementsSection";
import Style from "./AchievementsSection.module.css";
import useScreenSize from "../../../hooks/useScreenSize";

const AchievementsCard = ({ textPosition, imageSrc, title, description }) => {
    const { isSmallSize } = useScreenSize();
  return (
    <div className={Style["achievements-card-wrapper"]}>
      {textPosition === TextPosition.Right && !isSmallSize  ? (
        <>
          <img src={imageSrc} alt={title} />
          <div className={Style["text-wrapper"]}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
        </>
      ) : (
        <>
          <div className={Style["text-wrapper"]}>
            <h3>{title}</h3>
            <p>{description}</p>
          </div>
          <img src={imageSrc} alt={title} />
        </>
      )}
    </div>
  );
};

export default AchievementsCard;
