import Style from "./AchievementsSection.module.css";
import useScreenSize from "../../../hooks/useScreenSize";
import AchievementsCard from "./AchievementsCard";

export const TextPosition = {
  Left: "left",
  Right: "right",
};

const AchievementsSection = () => {
  const { isSmallSize } = useScreenSize();

  const achievements = [
    {
      textPosition: TextPosition.Right,
      imageSrc: isSmallSize ? "/phone_smartfoodie.png" : "/smartfoodie.png",
      title: "Smart Foodie Platform",
      description:
        "Smart Foodie is a comprehensive and easy-to-use platform featuring both web and mobile applications designed to support restaurant businesses. With Smart Foodie, clients can conveniently make reservations and place orders directly from their smartphones, making dining out simpler and more enjoyable.",
    },
    {
      textPosition: TextPosition.Left,
      imageSrc: isSmallSize ? "/phone_carauction.png" : "/carauction.png",
      title: "Global Car Auction System",
      description:
        "With the Global Car Auction System, users can join a worldwide car auction platform where sellers can easily list their vehicles. We've also created a logistics feature that helps our partners manage car deliveries smoothly, ensuring a seamless process.",
    },
    {
      textPosition: TextPosition.Right,
      imageSrc: isSmallSize ? "/phone_modernhouse.png" : "/modernhouse.png",
      title: "Modern House Website",
      description:
        "We have created a user-friendly website tailored to our partner's needs. From a personalized design to easy navigation, our focus was on delivering a seamless experience for customers browsing houses and metal structures on the website.",
    },
  ];

  return (
    <div className={Style["achievements-section-wrapper"]}>
      <h2>Smart Solutions, Real Results</h2>
      <div className={Style["achievements-section-body"]}>
        {achievements.map((achievement) => (
          <AchievementsCard
            textPosition={achievement.textPosition}
            imageSrc={achievement.imageSrc}
            title={achievement.title}
            description={achievement.description}
          />
        ))}
      </div>
    </div>
  );
};

export default AchievementsSection;
